import { formatRelative } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getSharePosts } from '../app/api';
import { base } from '../app/backend';
import '../app/fragments';
import '../app/styles.scss';
import SEO from '../components/seo';
import '../app/live.scss';

const POST_TRANSITION_DELAY = 7_000;
const POST_FETCH_DELAY = 30_000; // 30 seconds
let currentMemory = 0;

const query = graphql`
  {
    allFile(sort: { fields: name, order: ASC }, filter: { relativeDirectory: { eq: "memories" } }) {
      nodes {
        name
        id
        publicURL
      }
    }
  }
`;

function useTimer(duration, callback) {
  useEffect(() => {
    let timeout;
    let fn = callback;
    const tick = () => {
      Promise.resolve(fn()).then(() => {
        timeout = setTimeout(tick, duration);
      });
    };
    timeout = setTimeout(tick, duration);

    return () => {
      clearTimeout(timeout);
    };
  }, [duration]);
}

export default function LivePage({}) {
  const [posts, setPosts] = useState([]);
  const memories = useStaticQuery(query).allFile.nodes;

  useEffect(() => {
    getSharePosts(posts => {
      let images = posts.map(post => post.images).flat();
      preloadImages(images);
      setPosts(
        posts.sort((a, b) => (a.postedAt < b.postedAt ? -1 : a.postedAt > b.postedAt ? 1 : 0))
      );
    });
    preloadImages([0, 1, 2].map(i => memories[i].publicURL));
  }, []);

  useTimer(
    POST_TRANSITION_DELAY,
    useCallback(
      () =>
        setPosts(([_, ...posts]) => {
          if (posts.length) return posts;
          let memory = memories[currentMemory];
          currentMemory = (currentMemory + 1) % memories.length;
          if (currentMemory % 3 === 0) {
            preloadImages([0, 1, 2].map(i => memories[currentMemory + i].publicURL));
          }
          return [
            {
              message: 'Memories',
              images: [memory.publicURL],
              id: memory.id,
            },
          ];
        }),
      []
    )
  );

  useTimer(
    POST_FETCH_DELAY,
    useCallback(() => getLatestPosts(setPosts), [])
  );

  const post = posts[0];

  return (
    <section data-theme="green" className="live-page">
      <SEO title="Live" />
      <AnimatePresence>
        {post && <div className="backdrop" style={{ backgroundColor: 'black' }} />}
        <motion.div
          initial={{ opacity: 0 }}
          transition={{ duration: 1.5 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key={post?.id}
        >
          {post && <Tweet {...post} />}
        </motion.div>
      </AnimatePresence>
    </section>
  );
}

const preloadImage = src =>
  new Promise(r => {
    const image = new Image();
    image.onload = r;
    image.onerror = r;
    image.src = src;
  });

const preloadImages = urls => Promise.all(urls.map(preloadImage));

function getLatestPosts(setPosts) {
  return base('posts')
    .select({ filterByFormula: `DATETIME_DIFF(NOW(),postedAt, 'seconds') < 30` })
    .firstPage()
    .then(records => records.map(record => record.fields))
    .then(async latestPosts => {
      await preloadImages(latestPosts.map(post => JSON.parse(post.images)).flat());

      setPosts(posts => {
        let newPosts = latestPosts
          .filter(post => !posts.find(p => p.id === post.id))
          .map(({ images, ...post }) => ({ ...post, images: JSON.parse(images) }));
        let addedPosts = [...posts, ...newPosts];
        return addedPosts;
      });
    });
}

export function Tweet({ name, message, postedAt, email, images }) {
  return (
    <div className="livetweet">
      <div className="backdrop" style={{ backgroundColor: 'var(--background)' }} />
      <main className="image" style={{ alignItems: 'flex-start' }}>
        {images?.length > 0 && (
          <>
            <img className="background-image" src={images[0]} />
            <img className="foreground-image" src={images[0]} />
          </>
        )}
        <div className="content">
          <div className="main-content">
            {name && <p style={{ fontWeight: 'bold' }}>{name}</p>}
            {postedAt && <h6 className="date">{formatRelative(new Date(postedAt), new Date())}</h6>}
          </div>
          {message && <p style={{ lineHeight: '1em', fontSize: '2em' }}>{message}</p>}
        </div>
      </main>
    </div>
  );
}
